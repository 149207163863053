<template>
  <div>
    <v-card>
      <v-card-title>Chat Group Table</v-card-title>
      <v-row>
        <v-col>
          <v-data-table class="ml-6 mr-6" :items="items" :headers="headers">
            <template v-slot:item.edit="{ item }">
              <div>
                <v-btn
                  class="mx-2"
                  elevation="0"
                  fab
                  small
                  @click="editChatGroup(item)"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Modifier le groupe de chat</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editGroup.name"
            label="Nom du groupe"
            outlined
            hide-details
            readonly
          ></v-text-field>
          <v-row
            v-for="(param, index) in editGroup.parameters"
            :key="index"
            class="mt-3 outlined-row"
          >
            <v-col cols="12" md="5">
              <v-select
                v-model="param.gtrends_category_id"
                :items="categories"
                item-text="rmr_cat_name"
                item-value="id"
                label="Catégorie"
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="param.sv"
                label="Volume de recherche"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-btn
                class="mx-2"
                elevation="0"
                fab
                small
                @click="removeParameter(index)"
              >
                <v-icon color="red">
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addParameter">Ajouter une ligne</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveChatGroup">Enregistrer</v-btn>
          <v-btn color="secondary" @click="dialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import useAxios from "@/hooks/useAxios";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
export default {
  name: "chatGroupTable",
  data() {
    return {
      items: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Edit", value: "edit" },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
      dialog: false,
      editGroup: {
        id: "",
        name: "",
        parameters: [],
      },
      categories: [],
    };
  },
  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },
  created() {
    this.getChatGroups();
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const response = await this.axiosGet("/gtrends/categories");
      // console.log("response", response);
      this.categories = response.data;
    },
    async getChatGroups() {
      const res = await this.axiosGet("gtrends/chatgroups");
      // console.log(res);
      this.items = res.data;
    },
    async editChatGroup(item) {
      const res = await this.axiosGet(
        `gtrends/get_chat_group_params/${item.id}`
      );
      this.editGroup.id = item.id;
      this.editGroup.name = item.name;
      this.editGroup.parameters = res.data;
      console.log(this.editGroup);
      this.dialog = true;
    },
    async saveChatGroup() {
      await this.axiosPost(
        `gtrends/save_chat_group_params/${this.editGroup.id}`,
        { params: this.editGroup.parameters }
      );
      this.dialog = false;
      this.getChatGroups();
    },
    removeParameter(index) {
      this.editGroup.parameters.splice(index, 1);
    },
    addParameter() {
      this.editGroup.parameters.push({ gtrends_category_id: "", sv: "" });
    },
  },
};
</script>
<style scoped>
.outlined-row {
  border: 1px solid #e0e0e0;
  border-radius: 1px;
  padding: 1px;
  margin: 3px;
}
</style>
