<template>
  <div>
    <h1 class="text-2xl mb-6">Google Trends Notifications</h1>
    <chatGroupTable></chatGroupTable>
    <v-row justify="center" class="mt-12">
      <v-btn class="primary" @click="dialog = true"
        >Demande de nouveau canal</v-btn
      >
    </v-row>
    <v-dialog v-model="dialog">
      <ChatGroupCreation @close-dialog="dialog = false"></ChatGroupCreation>
    </v-dialog>
  </div>
</template>
<script>
import ChatGroupCreation from "@/components/gtrends/cards/chatGroupCreation.vue";
import chatGroupTable from "@/components/gtrends/tables/chatgroupTable.vue";
export default {
  name: "gTrendsNotifications",
  components: {
    chatGroupTable,
    ChatGroupCreation,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style></style>
