<template>
  <div class="chat-group-creation">
    <v-card class="mt-6 mb-6">
      <v-card-title>Demande de nouveau canal de notification </v-card-title>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            class="ml-6 mr-6"
            label="Nom du canal"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center">
          <v-btn class="ml-6 mr-6 primary" @click="addParameter">
            Ajouter un paramètre
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-for="(param, index) in parameters" :key="index" class="mt-3">
        <v-col cols="12" md="6">
          <v-select
            v-model="param.category"
            :items="categories"
            item-text="rmr_cat_name"
            item-value="id"
            class="ml-6 mr-6"
            label="Catégorie"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="param.searchVolume"
            class="ml-6 mr-6"
            label="Volume de recherche"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="parameters.length > 0" justify="center" class="mt-12 pb-6">
        <v-btn class="ml-6 mr-6 secondary" @click="saveChatGroupDemand"
          >Envoyer la demande</v-btn
        >
        <v-btn class="ml-6 mr-6 primary" @click="cancelParameters"
          >Annuler</v-btn
        >
      </v-row>
    </v-card>
  </div>
</template>
<script>
import useAxios from "@/hooks/useAxios";
export default {
  name: "ChatGroupCreation",
  components: {},
  data() {
    return {
      channelName: "",
      parameters: [],
      categories: [],
    };
  },
  setup() {
    const { axiosGet, axiosPost, isLoading } = useAxios();

    return {
      axiosGet,
      isLoading,
      axiosPost,
    };
  },
  async created() {
    await this.fetchCategories();
    // console.log("categories", this.categories);
  },
  methods: {
    async fetchCategories() {
      const response = await this.axiosGet("/gtrends/categories");
      // console.log("response", response);
      this.categories = response.data;
    },
    addParameter() {
      this.parameters.push({ category: "", searchVolume: "" });
    },
    cancelParameters() {
      this.parameters = [];
      this.$emit("close-dialog");
    },
    saveChatGroupDemand() {
      console.log(this.channelName, this.parameters);
    },
  },
};
</script>
<style scoped>
.chat-group-creation {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
